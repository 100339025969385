<template>
  <div>
    <search-form>
      <div class="d-inline-flex flex-wrap mb-4">
        <c-input v-model="search.id" label="ID" class="ma-5" dense hide-details />

        <c-input v-model="search.pin" :label="$t('coupons.pin')" dir="ltr" class="ma-5" dense hide-details />
      </div>

      <div class="mx-5 d-inline-flex w-100">
        <v-btn color="success" dark @click="filtering" small :loading="progressing">
          <v-icon small class="me-1"> mdi-filter </v-icon>
          {{ $t('shared.applyFilter') }}
        </v-btn>
      </div>
    </search-form>

    <div class="d-flex mb-4">
      <v-spacer />
      <export-excel :fields="json_fields" :fetch="fetchExcel" name="coupons" />

      <v-btn color="primary" dark to="create" small>
        <v-icon small class="me-1"> mdi-plus </v-icon>
        {{ $t('shared.add') }}
      </v-btn>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="context"
      @update:options="filtering"
      :server-items-length="count"
      :loading="progressing"
      :footer-props="$config.tableFooterProps"
      sort-by="id"
      must-sort
      class="elevation-2"
    >
      <template #[`item.actions`]="{item}">
        <div class="d-flex justify-end">
          <v-btn small fab text color="info" :to="`edit/${item.id}`">
            <v-icon> mdi-pencil </v-icon>
          </v-btn>

          <v-btn small fab text color="error" @click="removeItem(item)">
            <v-icon> mdi-delete </v-icon>
          </v-btn>
        </div>
      </template>

      <template #[`item.active`]="{item}">
        <v-icon v-if="item.active" color="success"> mdi-check </v-icon>
        <v-icon v-else color="secondary"> mdi-close </v-icon>
      </template>

      <template #[`item.amount`]="{item}">
        <currency-display :value="item.amount" :percent="item.discountType === 'PERCENT'" />
      </template>

      <template #[`item.availableFrom`]="{item}">
        <date-display :value="item.availableFrom" />
      </template>

      <template #[`item.availableUntil`]="{item}">
        <date-display :value="item.availableUntil" />
      </template>

      <template #[`item.user`]="{item}">
        <span v-if="item.user"> {{ item.user.fullName }} </span>
      </template>

      <template #[`item.used`]="{item}">
        <v-icon v-if="item.used" color="success"> mdi-check </v-icon>
        <v-icon v-else color="secondary"> mdi-close </v-icon>
      </template>

      <template v-slot:no-data>
        {{ $t('shared.noRecords') }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {toZone} from '@/lib/utils'
export default {
  name: 'Index',
  props: {
    type: {type: String}
  },
  data() {
    return {
      progressing: false,
      count: 1,
      context: {},
      items: [],
      headers: [
        {text: 'ID', value: 'id'},
        {
          text: this.$t('coupons.pin'),
          value: 'pin'
        },
        {
          text: this.$t('users.user'),
          value: 'user'
        },
        {
          text: this.$t('shared.discount'),
          value: 'amount'
        },
        {
          text: this.$t('coupons.availableFrom'),
          value: 'availableFrom'
        },
        {
          text: this.$t('coupons.availableUntil'),
          value: 'availableUntil'
        },
        {
          text: this.$t('coupons.used'),
          value: 'used'
        },
        {
          text: this.$t('shared.active'),
          value: 'active'
        },
        {text: this.$t('shared.actions'), value: 'actions', sortable: false}
      ],
      search: {
        id: null,
        pin: null
      },
      json_fields: {
        [this.$t('coupons.pin')]: 'pin',
        [this.$t('shared.discount')]: {
          callback: (item) => {
            if (item.discountType === 'PERCENT') {
              return `${item.amount} ${this.$t('shared.percent')}`
            }

            return `${item.amount.toLocaleString()} ${this.$t('shared.IRR')}`
          }
        },
        [this.$t('coupons.availableFrom')]: {
          field: 'availableFrom',
          callback: (value) => {
            return toZone(value)
          }
        },
        [this.$t('coupons.availableUntil')]: {
          field: 'availableUntil',
          callback: (value) => {
            return toZone(value)
          }
        },
        [this.$t('coupons.used')]: {
          field: 'used',
          callback: (value) => {
            return value ? this.$t('shared.yes') : this.$t('shared.no')
          }
        },
        [this.$t('shared.active')]: {
          field: 'active',
          callback: (value) => {
            return value ? this.$t('shared.yes') : this.$t('shared.no')
          }
        }
      }
    }
  },
  methods: {
    filter(isCount) {
      const filter = {}
      if (!isCount) {
        filter['sort[0].column'] = this.context.sortBy[0]
        filter['sort[0].type'] = this.context.sortDesc[0] ? 'ASCENDING' : 'DESCENDING'
        filter.size = this.context.itemsPerPage
        filter.page = this.context.page
      }

      const searchItems = Object.keys(this.search)
      searchItems.forEach((item) => {
        const val = this.search[item]
        if (val || val === false || val === 0) {
          filter[item] = val
        }
      })
      return filter
    },
    fetchExcel() {
      return this.$api.coupons.export(this.filter()).catch((err) => {
        this.$showError(err)
      })
    },
    getItems(filter) {
      return this.$api.coupons.getAll(filter).then((res) => {
        this.items = res
      })
    },
    getCount(filter) {
      return this.$api.coupons.count(filter).then((res) => {
        this.count = res
      })
    },
    filtering() {
      this.progressing = true
      Promise.all([this.getItems(this.filter()), this.getCount(this.filter(true))])
        .then((res) => {
          this.progressing = false
        })
        .catch((err) => {
          this.$showError(err)
        })
    },
    async removeItem(item) {
      try {
        if (!(await this.$confirm())) return

        await this.$api.coupons.delete(item.id)
        this.filtering()
        this.$showSuccess(this.$t('coupons.couponRemoved'))
      } catch (error) {
        this.$showError(error)
      }
    }
  }
}
</script>
